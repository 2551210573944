import React from 'react'
import {Helmet} from 'react-helmet'

import Layout from '../components/layout'
import titleSketch from '../../static/sharon-sketch-title.png'

const pageTitle = 'The Golden Rule'

export default function About() {
  return (
    <Layout>
      <main role="main">
        <Helmet title={`About`}>
          <meta name="description" content="Animated series from Famebot created by Laramie Dennis and Ricky de Laveaga." />
        </Helmet>
        <h1
          style={{
            textAlign: `center`,
          }}
        >
          {pageTitle}
        </h1>

        <section>
          <article style={{fontSize: `1.125em`}}>
            <p>A Midwestern grandma moves to a SoCal retirement{' '}
            community&#8212;and finds herself terrorized by a clique of{' '}
            80something mean&nbsp;girls.</p>
            <figure
              style={{display: 'inline-block', textAlign: 'center', width: '100%',}}
            >
              <img
                src={titleSketch}
                width="576"
                alt={pageTitle}
              />
            </figure>
            <p>Created by{' '}
            <a href="https://laramiedennis.com/">Laramie Dennis</a>{' '}
            and <a href="https://rdela.com/">Ricky de Laveaga</a>,{' '}
            this animated series from{' '}
            <a href="https://famebot.com/">Famebot</a> follows Sharon,{' '}
            pictured above in an early&nbsp;sketch.</p>
          </article>
        </section>
      </main>
    </Layout>
  )
}
